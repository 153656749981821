<template>
  <div class="pages">
    <div class="type-wrapper">
      <span class="item" :class="{active: typeIndex === 0}" @click="handleTypeClick(0)">视频</span>
      <span class="item" :class="{active: typeIndex === 1}" @click="handleTypeClick(1)">图片</span>
    </div>
    <div class="album-list-wrapper" v-if="typeIndex === 0">
      <div class="item">
        <p class="title">视频</p>
        <div class="media-wrapper">
          <div class="media-item" v-for="(item,index) in videoList" :key="index" @click="handleVideoClick(item.video)">
            <el-image class="image" :src="item.img" fit="fill"/>
            <el-image class="play" src="/static/images/play.png" fit="cover"/>
          </div>
        </div>
      </div>
    </div>
    <div class="album-list-wrapper" v-if="typeIndex === 1">
      <div class="item" v-for="(item,index) in imageList" :key="index">
        <p class="title">{{item.title}}</p>
        <div class="media-wrapper">
          <div class="media-item" v-for="(item2,index2) in item.img" :key="index2" @click="handleImageClick(item2)">
            <el-image class="image" :src="item2" fit="fill"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let map = null // eslint-disable-line
export default {
  name: 'HousesAlbum',
  data() {
    return {
      imageList: [],
      videoList: [],
      typeIndex: 0,
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      let resVideo = await this.http({
        url: '/api/report/projectAlbumVideo',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      this.videoList = resVideo.data

      let resImage = await this.http({
        url: '/api/report/projectAlbumImage',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      this.imageList = resImage.data
    },
    handleTypeClick(e) {
      this.typeIndex = e
    },
    handleImageClick(url){
      window.open(url,'_blank')
    },
    handleVideoClick(url){
      window.open(url,'_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import "HousesAlbum";
</style>
